import React, { useEffect, useRef } from "react";
import { InputGroup, Form, Col, Container } from "react-bootstrap";
import PointCloud from "./sections-six/PointCloud";

const SectionSix = () => {
  const sectionRef = useRef(null);
  const inputRef = useRef(null);
  const placeholdersText = [
    "Unlock the power of digidom",
    "Débloquez le pouvoir du digidom",
    "إطلاق العنان لقوة الديجيتوم",
    "释放数字王国的力量",
    "Débloquer nguya ya digidom",
    "Fungua nguvu ya digidom",
    "Desbloqueie o poder do digidom",
    "Nutzen Sie die Macht von digidom",
    "Lås upp digidomens kraft",
    "Ontgrendel de kracht van digidom"
  ];
  const currentIndex = useRef(0);
  const timer = useRef(null);

  useEffect(() => {
    animatePlaceHolder();
    return () => {
      clearInterval(timer.current);
    };
  }, []);

  const animatePlaceHolder = () => {
    timer.current = setInterval(() => {
      if (
        currentIndex.current < placeholdersText.length - 1 ||
        (currentIndex.current == placeholdersText.length - 1 &&
          inputRef.current.placeholder.length <
            placeholdersText[currentIndex.current].length)
      ) {
        if (
          inputRef.current.placeholder.length <
          placeholdersText[currentIndex.current].length
        ) {
          inputRef.current.placeholder =
            inputRef.current.placeholder +
            placeholdersText[currentIndex.current][
              inputRef.current.placeholder.length
            ];
        } else {
          inputRef.current.placeholder = "";
          currentIndex.current++;
        }
      } else {
        currentIndex.current = 0;
        inputRef.current.placeholder = "";
      }
    }, 250);
  };

  const onInputChange = (e) => {
    if (e.target.value == 0) {
      currentIndex.current = 0;
      inputRef.current.placeholder = "";
      animatePlaceHolder();
    }
    else{
      clearInterval(timer.current);
    }
  };
  return (
    <section ref={sectionRef} className="section-6 mt-sm-5">
      <div className="position-relative w-100 h-100">
        <div className="position-absolute top-0 bottom-0 start-0 end-0 h-100 w-100">
          <PointCloud sectionRef={sectionRef} />
        </div>
        <div className="position-absolute top-0 bottom-0 start-0 end-0 h-100 w-100">
          <div className="justify-content-center align-items-center w-100 h-100">
            <div className="position-relative h-100">
              <Container
                fluid
                className="h-100 d-flex justify-content-center align-items-center"
              >
                <Col md={6}>
                  <InputGroup size="lg">
                    <Form.Control ref={inputRef} onChange={onInputChange} />
                    <InputGroup.Text as="button" className="btn btn-dark">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" />
                        <path d="M21 21l-6 -6" />
                      </svg>
                    </InputGroup.Text>
                  </InputGroup>
                </Col>
              </Container>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionSix;
