import React, { useEffect, useRef, forwardRef } from "react";
import { Card } from "react-bootstrap";
import { gsap } from "gsap";

const CardOne = forwardRef((props, ref) => {
  const lineOneRef = useRef(null);
  const lineTwoRef = useRef(null);
  const lineThreeRef = useRef(null);

  useEffect(() => {
    let ctx = gsap.context(() => {
      const tl1 = gsap.timeline({ repeat: -1 });
      const tl2 = gsap.timeline({ repeat: -1 });
      const tl3 = gsap.timeline({ repeat: -1 });
      Array.from(lineOneRef.current.children).forEach((item) => {
        gsap.set(item, { display: "none" });
      });
      Array.from(lineTwoRef.current.children).forEach((item) => {
        gsap.set(item, { display: "none" });
      });
      Array.from(lineThreeRef.current.children).forEach((item) => {
        gsap.set(item, { display: "none" });
      });

      Array.from(lineOneRef.current.children).forEach((item, key) => {
        tl1.set(item, { display: "block" });
        tl1.fromTo(
          item.firstChild.firstChild,

          { y: "100%", display: "none" },
          {
            y: "0%",
            display: "block",
            duration: 0.5,
          }
        );
        tl1.set(item, { display: "none", delay: 4 });
      });

      tl2.delay(0.5);
      Array.from(lineTwoRef.current.children).forEach((item, key) => {
        tl2.set(item, { display: "block" });
        tl2.fromTo(
          item.firstChild.firstChild,

          { y: "100%", display: "none" },
          {
            y: "0%",
            display: "block",
            duration: 0.5,
          }
        );
        tl2.set(item, { display: "none", delay: 4 });
      });

      tl3.delay(1);
      Array.from(lineThreeRef.current.children).forEach((item, key) => {
        tl3.set(item, { display: "block" });
        tl3.fromTo(
          item.firstChild.firstChild,

          { y: "100%", display: "none" },
          {
            y: "0%",
            display: "block",
            duration: 0.5,
          }
        );
        tl3.set(item, { display: "none", delay: 4 });
      });
    });

    return () => ctx.revert();
  }, []);

  return (
    <Card ref={ref} className="card-1 position-absolute border-0">
      <Card.Body className="d-flex justify-content-center align-items-center">
        <div className="text-center">
          <div ref={lineOneRef}>
            <h5 className="mb-0 text-uppercase">
              <span className="d-block overflow-hidden">
                <span className="d-inline-block">Ciao</span>
              </span>
            </h5>
            <h5 className="mb-0 text-uppercase">
              <span className="d-block overflow-hidden">
                <span className="d-inline-block">Ollá</span>
              </span>
            </h5>
            <h5 className="mb-0 text-uppercase">
              <span className="d-block overflow-hidden">
                <span className="d-inline-block">你好</span>
              </span>
            </h5>
          </div>
          <div ref={lineTwoRef}>
            <h5 className="mb-0 text-uppercase">
              <span className="d-block overflow-hidden">
                <span className="d-inline-block">Hello</span>
              </span>
            </h5>
            <h5 className="mb-0 text-uppercase">
              <span className="d-block overflow-hidden">
                <span className="d-inline-block">Bonjour</span>
              </span>
            </h5>
            <h5 className="mb-0 text-uppercase">
              <span className="d-block overflow-hidden">
                <span className="d-inline-block">مرحبًا</span>
              </span>
            </h5>
          </div>
          <div ref={lineThreeRef}>
            <h5 className="mb-0 text-uppercase">
              <span className="d-block overflow-hidden">
                <span className="d-inline-block">Witam</span>
              </span>
            </h5>
            <h5 className="mb-0 text-uppercase">
              <span className="d-block overflow-hidden">
                <span className="d-inline-block">こんにちは</span>
              </span>
            </h5>
            <h5 className="mb-0 text-uppercase">
              <span className="d-block overflow-hidden">
                <span className="d-inline-block">Guten Tag</span>
              </span>
            </h5>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
});

export default CardOne;
