import React, { useEffect, useRef, forwardRef } from "react";
import { Card } from "react-bootstrap";
import { gsap } from "gsap";

const CardSix = forwardRef((props, ref) => {
  const textRef = useRef(null);

  useEffect(() => {
    let ctx = gsap.context(() => {
      const tl1 = gsap.timeline({ repeat: -1 });
      Array.from(textRef.current.children).forEach((item) => {
        gsap.set(item, { display: "none" });
      });

      Array.from(textRef.current.children).forEach((item, key) => {
        tl1.set(item, { display: "block" });
        tl1.fromTo(
          item.firstChild.firstChild,

          { y: "50%", display: "none" },
          {
            y: "0%",
            display: "block",
            duration: 0.5,
            stagger: 0.1
          }
        );
        tl1.set(item, { display: "none", delay: 2 });
      });
    });

    return () => ctx.revert();
  }, []);

  return (
    <Card ref={ref} className="card-6 position-absolute border-0">
      <Card.Body className="d-flex justify-content-center align-items-center">
        <div className="text-center">
          <div ref={textRef} className="btn btn-dark text-uppercase rounded-pill px-3 py-2">
            <h6 className="mb-0">
              <span className="d-block overflow-hidden">
                <span className="d-inline-block">Social Media</span>
              </span>
            </h6>
            <h6 className="mb-0">
              <span className="d-block overflow-hidden">
                <span className="d-inline-block">Web Sites</span>
              </span>
            </h6>
            <h6 className="mb-0">
              <span className="d-block overflow-hidden">
                <span className="d-inline-block">Dark Net</span>
              </span>
            </h6>
            <h6 className="mb-0">
              <span className="d-block overflow-hidden">
                <span className="d-inline-block">Others</span>
              </span>
            </h6>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
});

export default CardSix;
