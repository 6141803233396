import React, { useEffect, useRef } from "react";
import { Container } from "react-bootstrap";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import CardOne from "./sections-four/CardOne";
import CardTwo from "./sections-four/CardTwo";
import CardThree from "./sections-four/CardThree";
import CardFour from "./sections-four/CardFour";
import CardFive from "./sections-four/CardFive";
import CardSix from "./sections-four/CardSix";

gsap.registerPlugin(ScrollTrigger);

const SectionFour = () => {
  const sectionRef = useRef(null);
  const cardOneRef = useRef(null);
  const cardTwoRef = useRef(null);
  const cardThreeRef = useRef(null);
  const cardFourRef = useRef(null);
  const cardFiveRef = useRef(null);
  const cardSixRef = useRef(null);

  useEffect(() => {
    let ctx = gsap.context(() => {
      let mm = gsap.matchMedia();
      mm.add("(min-width: 800px)", () => {
        gsap.set(cardOneRef.current, {
          xPercent: -50,
          yPercent: -50,
          left: "40%",
          top: "40%",
        });
        gsap.set(cardTwoRef.current, {
          xPercent: -50,
          yPercent: -50,
          left: "60%",
          top: "40%",
        });
        gsap.set(cardThreeRef.current, {
          xPercent: -50,
          yPercent: -50,
          left: "60%",
          top: "60%",
        });
        gsap.set(cardFourRef.current, {
          xPercent: -50,
          yPercent: -50,
          left: "40%",
          top: "60%",
        });
        gsap.set(cardFiveRef.current, {
          xPercent: -50,
          yPercent: -50,
          left: "50%",
          top: "65%",
        });
        gsap.set(cardSixRef.current, {
          xPercent: -50,
          yPercent: -50,
          left: "50%",
          top: "35%",
        });

        gsap
          .timeline({
            scrollTrigger: {
              trigger: sectionRef.current,
              start: "50% 80%",
              end: "50% 50%",
            },
            defaults: { duration: 2 },
          })
          .to(cardOneRef.current, { left: "15%", top: "30%" });
        gsap
          .timeline({
            scrollTrigger: {
              trigger: sectionRef.current,
              start: "50% 80%",
              end: "50% 50%",
            },
            defaults: { duration: 2 },
          })
          .to(cardTwoRef.current, { left: "85%", top: "30%" });
        gsap
          .timeline({
            scrollTrigger: {
              trigger: sectionRef.current,
              start: "50% 80%",
              end: "50% 50%",
            },
            defaults: { duration: 2 },
          })
          .to(cardThreeRef.current, { left: "85%", top: "70%" });
        gsap
          .timeline({
            scrollTrigger: {
              trigger: sectionRef.current,
              start: "50% 80%",
              end: "50% 50%",
            },
            defaults: { duration: 2 },
          })
          .to(cardFourRef.current, { left: "15%", top: "70%" });
        gsap
          .timeline({
            scrollTrigger: {
              trigger: sectionRef.current,
              start: "50% 80%",
              end: "50% 50%",
            },
            defaults: { duration: 2 },
          })
          .to(cardFiveRef.current, { top: "80%" });
        gsap
          .timeline({
            scrollTrigger: {
              trigger: sectionRef.current,
              start: "50% 80%",
              end: "50% 50%",
            },
            defaults: { duration: 2 },
          })
          .to(cardSixRef.current, { top: "20%" });
      });
      mm.add("(max-width: 799px)", () => {
        gsap.set(cardOneRef.current, {
          xPercent: -50,
          yPercent: -50,
          left: "50%",
          top: "35%",
        });
        gsap.set(cardTwoRef.current, {
          xPercent: -50,
          yPercent: -50,
          left: "50%",
          top: "60%",
        });
        gsap.set(cardThreeRef.current, {
          display: "none",
          visibility: "hidden",
        });
        gsap.set(cardFourRef.current, {
          display: "none",
          visibility: "hidden",
        });
        gsap.set(cardFiveRef.current, {
          display: "none",
          visibility: "hidden",
        });
        gsap.set(cardSixRef.current, {
          display: "none",
          visibility: "hidden",
        });
      });
    });

    return () => ctx.revert();
  }, []);

  return (
    <section ref={sectionRef} className="section-4 mt-5">
      <Container fluid className="position-relative h-100">
        <div className="position-absolute top-0 bottom-0 start-0 end-0 d-flex justify-content-center">
          <CardOne ref={cardOneRef} />
          <CardTwo ref={cardTwoRef} />
          <CardThree ref={cardThreeRef} />
          <CardFour ref={cardFourRef} />
          <CardFive ref={cardFiveRef} />
          <CardSix ref={cardSixRef} />
        </div>
        <div className="h-100">
          <div className="d-flex justify-content-center align-items-md-center h-100">
            <h2 className="display-2 fw-bold text-center pt-md-0 pt-5 mb-0">
              Digital Guardian
              <br />
              Monitoring
            </h2>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default SectionFour;
