import React, { useEffect, useRef } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { gsap, Power3 } from "gsap";
import image1 from "../assets/images/01.jpg";
import image2 from "../assets/images/02.jpg";
import image3 from "../assets/images/03.jpg";
import image4 from "../assets/images/04.jpg";
import image5 from "../assets/images/05.jpg";
import image6 from "../assets/images/06.jpg";
import image7 from "../assets/images/07.jpg";
import image8 from "../assets/images/08.jpg";
import image9 from "../assets/images/09.jpg";
import image10 from "../assets/images/10.jpg";
import image11 from "../assets/images/11.jpg";
import image12 from "../assets/images/12.jpg";
import image13 from "../assets/images/13.jpg";

const SplashScreen = (props) => {
  const cardRef = useRef(null);
  const images = [
    "image1.jpg",
    "image2.jpg",
    "image3.jpg",
    "image4.jpg",
    "image5.jpg",
    "image6.jpg",
    "image7.jpg",
    "image8.jpg",
    "image9.jpg",
    "image10.jpg",
    "image11.jpg",
    "image12.jpg",
    "image13.jpg",
  ];

  useEffect(() => {
    let ctx = gsap.context(() => {
      gsap
        .fromTo(
          ".splash-text-1",
          {
            y: -300,
            opacity: 1,
          },
          {
            y: 0,
            ease: "elastic",
            duration: 0.2,
          }
        )
        .then(() => {
          gsap.to(".splash-text-1", {
            x: "-55%",
            ease: "power1.out",
            duration: 0.1,
            delay: 0.3,
          });
          gsap
            .fromTo(
              ".splash-text-2",
              {
                opacity: 0,
                x: "50%",
              },
              {
                opacity: 1,
                x: "55%",
                ease: "power1.in",
                duration: 0.1,
                delay: 0.3,
              }
            )
            .then(() => {
              gsap
                .to(".splash-text-2", {
                  y: 70,
                  ease: "power1.in",
                  duration: 0.2,
                  delay: 0.3,
                })
                .then(() => {
                  gsap.fromTo(
                    ".splash-text-1",
                    {},
                    {
                      x: 0,
                      ease: "power1.out",
                      duration: 0.2,
                      delay: 0.3,
                    }
                  );
                  gsap
                    .fromTo(
                      ".splash-text-2",
                      {},
                      {
                        x: 0,
                        ease: "power1.out",
                        duration: 0.2,
                        delay: 0.3,
                      }
                    )
                    .then(() => {
                      gsap.set(".container-1 .col-md-12:nth-child(1)", {
                        opacity: 0,
                        width: 0,
                        height: 0,
                        visibility: "hidden",
                        display: "none",
                      });
                      gsap.set(".container-1 .col-md-12:nth-child(3)", {
                        opacity: 0,
                        width: 0,
                        height: 0,
                        visibility: "hidden",
                        display: "none",
                      });
                      gsap.set(".container-2 .col-md-12:nth-child(1)", {
                        opacity: 0,
                        width: 0,
                        height: 0,
                        visibility: "hidden",
                        display: "none",
                      });
                      gsap.set(".container-2 .col-md-12:nth-child(3)", {
                        opacity: 0,
                        width: 0,
                        height: 0,
                        visibility: "hidden",
                        display: "none",
                      });
                      
                      gsap.fromTo(
                        ".container-1 .col-md-12:nth-child(2) .splash-text-1",
                        {
                          position: "absolute",
                        },
                        {
                          position: "absolute",
                          top: "0",
                          ease: "none",
                          duration: 0.1,
                          delay: 0.1,
                        }
                      );
                      document.querySelector(".container-2 .col-md-12:nth-child(2) .splash-text-2").innerHTML = "DigiDom"
                      gsap.fromTo(
                        ".container-2 .col-md-12:nth-child(2) .splash-text-2",
                        {
                          position: "absolute",
                        },
                        {
                          position: "absolute",
                          bottom: 100,
                          ease: "none",
                          fontWeight: "600",
                          text : "DigiDom",
                          duration: 0.1,
                          delay: 0.1,
                        }
                      );
                      const timeline = gsap.timeline();
                      images.forEach((image, index) => {
                        const tween = gsap.fromTo(
                          cardRef.current.children[index],
                          {
                            autoAlpha: 0,
                            opacity: 1,
                            display: "block",
                            zoom: index == 0 ? 0.1 : 1,
                          },
                          {
                            autoAlpha: 1,
                            ease: "none",
                            opacity: index == images.length - 1 ? 1 : 0,
                            display:
                              index == images.length - 1 ? "block" : "none",
                            zoom: 1,
                            duration: index == 0 ? 0.2 : 0.1,
                          }
                        );

                        timeline.add(tween);
                      });

                      timeline.play().then(() => {
                        gsap.to(".container-3 img", {
                          autoAlpha: 1,
                          zoom: 0.001,
                          display: "none",
                          duration: 0.2,
                          delay: 0.1,
                        });
                        gsap.to(
                          ".container-1 .col-md-12:nth-child(2) .splash-text-1",
                          {
                            top: "40%",
                            ease: "none",
                            duration: 0.2,
                            delay: 0.1,
                          }
                        );
                        gsap.to(
                          ".container-2 .col-md-12:nth-child(2) .splash-text-2",
                          {
                            bottom: "49.5%",
                            ease: "none",
                            duration: 0.2,
                            delay: 0.1,
                          }
                        );
                        gsap
                          .to(".splash-overlay", {
                            opacity: 0,
                            ease: "none",
                            duration: 0.05,
                            delay: 0.4,
                          })
                          .then(() => {
                            props.setLoading(false);
                          });
                      });
                    });
                });
            });
        });
    });

    return () => ctx.revert();
  }, []);

  return (
    <div className="splash-overlay">
      <Container fluid className="h-100 container-1">
        <Row className="h-100">
          <Col className="d-flex flex-column align-items-center justify-content-center">
            <Col md={12}>
              <div className="text-center mb-5">
                <h1 className="d-flex align-items-center justify-content-center display-1 fw-semibold text-white">
                  <span className="splash-text splash-text-1">Power Of</span>
                </h1>
              </div>
            </Col>
            <Col md={12}>
              <div className="text-center mb-5 mt-5">
                <h1 className="d-flex align-items-center justify-content-center display-1 fw-semibold text-white">
                  <span className="splash-text splash-text-1">Power Of</span>
                </h1>
              </div>
            </Col>
            <Col md={12}>
              <div className="text-center mb-5 mt-5">
                <h1 className="d-flex align-items-center justify-content-center display-1 fw-semibold text-white">
                  <span className="splash-text splash-text-1">Power Of</span>
                </h1>
              </div>
            </Col>
          </Col>
        </Row>
      </Container>
      <Container fluid className="h-100 position-absolute top-0 container-2">
        <Row className="h-100">
          <Col className="d-flex flex-column align-items-center justify-content-center">
            <Col md={12}>
              <div className="text-center mb-5">
                <h1 className="d-flex align-items-center justify-content-center display-1 text-white">
                  <span className="splash-text splash-text-2">CyberData</span>
                </h1>
              </div>
            </Col>
            <Col md={12}>
              <div className="text-center mb-5 mt-5">
                <h1 className="d-flex align-items-center justify-content-center display-1 text-white">
                  <span className="splash-text splash-text-2">Monitoring</span>
                </h1>
              </div>
            </Col>
            <Col md={12}>
              <div className="text-center mb-5 mt-5">
                <h1 className="d-flex align-items-center justify-content-center display-1 text-white">
                  <span className="splash-text splash-text-2">EReputation</span>
                </h1>
              </div>
            </Col>
          </Col>
        </Row>
      </Container>
      <Container fluid className="h-100 position-absolute top-0 container-3">
        <Row className="h-100">
          <Col className="d-flex flex-column align-items-center justify-content-center">
            <Col md={4} className="">
              <Card
                className="d-flex align-items-center justify-content-center"
                ref={cardRef}
              >
                <Card.Img src={image1} />
                <Card.Img src={image2} />
                <Card.Img src={image3} />
                <Card.Img src={image4} />
                <Card.Img src={image5} />
                <Card.Img src={image6} />
                <Card.Img src={image7} />
                <Card.Img src={image8} />
                <Card.Img src={image9} />
                <Card.Img src={image10} />
                <Card.Img src={image11} />
                <Card.Img src={image12} />
                <Card.Img src={image13} />
              </Card>
            </Col>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SplashScreen;
