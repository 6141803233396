import React, { forwardRef } from "react";
import { Card } from "react-bootstrap";
import video02 from "../../assets/videos/video-02.mp4";
import poster03 from "../../assets/images/poster-03.jpg";

const CardFour = forwardRef((props, ref) => {
  return (
    <Card ref={ref} className="card-4 position-absolute border-0">
      <Card.Body className="p-0" style={{backgroundImage : "url("+poster03+")"}}>
        <video className="w-100" loop muted playsInline autoPlay>
          <source src={video02}></source>
        </video>
      </Card.Body>
    </Card>
  );
});

export default CardFour;
