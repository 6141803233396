import React, { forwardRef } from "react";
import { Card } from "react-bootstrap";
import video01 from "../../assets/videos/video-01.mp4";
import poster02 from "../../assets/images/poster-02.jpg";

const CardTwo = forwardRef((props, ref) => {
  return (
    <Card ref={ref} className="card-2 position-absolute border-0">
      <Card.Body className="p-0" style={{backgroundImage : "url("+poster02+")"}}>
        <video className="w-100" loop muted playsInline autoPlay>
          <source src={video01}></source>
        </video>
      </Card.Body>
    </Card>
  );
});

export default CardTwo;
