import React, { useEffect, useState, useRef, forwardRef } from "react";
import { Card } from "react-bootstrap";
import { gsap } from "gsap";

const CardThree = forwardRef((props, ref) => {
  const counter = useRef(0);
  const counterRef = useRef(null);

  useEffect(() => {
    let ctx = gsap.context(() => {
      const incrementTimeline = gsap.timeline({ repeat: -1 });
      incrementTimeline.to(
        {},
        {
          duration: 3000,
          onUpdate: () => {
            counter.current = Math.round(incrementTimeline.progress() * 3000);
            counterRef.current.innerText = counter.current + "M";
          },
          ease: "power2.out",
        }
      );
      gsap.fromTo(
        counterRef.current,
        { scale: 0.9 },
        { scale: 1, yoyo: true, repeat: -1, ease: "power2.out" }
      );
    });

    return () => ctx.revert();
  }, []);

  return (
    <Card ref={ref} className="card-3 position-absolute border-0">
      <Card.Body className="d-flex justify-content-center align-items-top">
        <Card.Title className="mb-0 text-center text-uppercase">Scraped Data</Card.Title>
        <div className="position-absolute top-0 start-0 h-100 w-100">
          <div className="d-flex justify-content-center align-items-end h-100 w-100 pb-1">
            <h2 ref={counterRef}>0M</h2>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
});

export default CardThree;
