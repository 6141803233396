import React, { useEffect, useRef } from "react";
import { Card } from "react-bootstrap";
import { gsap } from "gsap";
import tunisia_flag from "../assets/flags/tunisia.png";
import france_flag from "../assets/flags/france.png";
import qatar_flag from "../assets/flags/qatar.png";
import cameroon_flag from "../assets/flags/cameroon.png";
import nigeria_flag from "../assets/flags/nigeria.png";
import us_flag from "../assets/flags/united-states.png";
import portugal_flag from "../assets/flags/portugal.png";
import malawi_flag from "../assets/flags/malawi.png";
import sweden_flag from "../assets/flags/sweden.png";
import senegal_flag from "../assets/flags/senegal.png";
import sa_flag from "../assets/flags/south-africa.png";
import netherlands_flag from "../assets/flags/netherlands.png";
import tanzania_flag from "../assets/flags/tanzania.png";
import germany_flag from "../assets/flags/germany.png";
import ic_flag from "../assets/flags/ivory-coast.png";
import scotland_flag from "../assets/flags/scotland.png";

const SectionTwo = () => {
  const sectionRef = useRef(null);
  const itemsGroupOneRef = useRef(null);
  const itemsGroupTwoRef = useRef(null);

  useEffect(() => {
    let ctx = gsap.context(() => {
      animateCards(itemsGroupOneRef.current);
      animateCards(itemsGroupTwoRef.current);
    });

    return () => ctx.revert();
  }, []);

  const animateCards = (itemGroup) => {
    const containerWidth = itemGroup.clientWidth;
    gsap.fromTo(
      itemGroup,
      {
        x: 0,
      },
      {
        x: -containerWidth,
        duration: containerWidth / 50,
        ease: "none",
        repeat: -1,
      }
    );
  };

  return (
    <section ref={sectionRef} className="section-2 py-5">
      <div className="items-group d-flex">
        <div ref={itemsGroupOneRef} className="items d-flex">
          <Card className="border-0 bg-transparent">
            <Card.Body className="d-flex align-items-center">
              <img className="me-2" src={tunisia_flag} />
              <Card.Title as="h2" className="mb-0">
                Tunsia
              </Card.Title>
            </Card.Body>
          </Card>
          <Card className="border-0 bg-transparent">
            <Card.Body className="d-flex align-items-center">
              <img className="me-2" src={france_flag} />
              <Card.Title as="h2" className="mb-0">
                France
              </Card.Title>
            </Card.Body>
          </Card>
          <Card className="border-0 bg-transparent">
            <Card.Body className="d-flex align-items-center">
              <img className="me-2" src={qatar_flag} />
              <Card.Title as="h2" className="mb-0">
                Qatar
              </Card.Title>
            </Card.Body>
          </Card>
          <Card className="border-0 bg-transparent">
            <Card.Body className="d-flex align-items-center">
              <img className="me-2" src={cameroon_flag} />
              <Card.Title as="h2" className="mb-0">
                Cameroon
              </Card.Title>
            </Card.Body>
          </Card>
          <Card className="border-0 bg-transparent">
            <Card.Body className="d-flex align-items-center">
              <img className="me-2" src={nigeria_flag} />
              <Card.Title as="h2" className="mb-0">
                Nigeria
              </Card.Title>
            </Card.Body>
          </Card>
          <Card className="border-0 bg-transparent">
            <Card.Body className="d-flex align-items-center">
              <img className="me-2" src={us_flag} />
              <Card.Title as="h2" className="mb-0">
                United States
              </Card.Title>
            </Card.Body>
          </Card>
          <Card className="border-0 bg-transparent">
            <Card.Body className="d-flex align-items-center">
              <img className="me-2" src={portugal_flag} />
              <Card.Title as="h2" className="mb-0">
                Portugal
              </Card.Title>
            </Card.Body>
          </Card>
          <Card className="border-0 bg-transparent">
            <Card.Body className="d-flex align-items-center">
              <img className="me-2" src={malawi_flag} />
              <Card.Title as="h2" className="mb-0">
                Malawi
              </Card.Title>
            </Card.Body>
          </Card>
          <Card className="border-0 bg-transparent">
            <Card.Body className="d-flex align-items-center">
              <img className="me-2" src={sweden_flag} />
              <Card.Title as="h2" className="mb-0">
                Sweden
              </Card.Title>
            </Card.Body>
          </Card>
          <Card className="border-0 bg-transparent">
            <Card.Body className="d-flex align-items-center">
              <img className="me-2" src={senegal_flag} />
              <Card.Title as="h2" className="mb-0">
                Senegal
              </Card.Title>
            </Card.Body>
          </Card>
          <Card className="border-0 bg-transparent">
            <Card.Body className="d-flex align-items-center">
              <img className="me-2" src={sa_flag} />
              <Card.Title as="h2" className="mb-0">
                South Africa
              </Card.Title>
            </Card.Body>
          </Card>
          <Card className="border-0 bg-transparent">
            <Card.Body className="d-flex align-items-center">
              <img className="me-2" src={netherlands_flag} />
              <Card.Title as="h2" className="mb-0">
                Netherlands
              </Card.Title>
            </Card.Body>
          </Card>
          <Card className="border-0 bg-transparent">
            <Card.Body className="d-flex align-items-center">
              <img className="me-2" src={tanzania_flag} />
              <Card.Title as="h2" className="mb-0">
                Tanzania
              </Card.Title>
            </Card.Body>
          </Card>
          <Card className="border-0 bg-transparent">
            <Card.Body className="d-flex align-items-center">
              <img className="me-2" src={germany_flag} />
              <Card.Title as="h2" className="mb-0">
                Germany
              </Card.Title>
            </Card.Body>
          </Card>
          <Card className="border-0 bg-transparent">
            <Card.Body className="d-flex align-items-center">
              <img className="me-2" src={ic_flag} />
              <Card.Title as="h2" className="mb-0">
                Ivory Coast
              </Card.Title>
            </Card.Body>
          </Card>
          <Card className="border-0 bg-transparent">
            <Card.Body className="d-flex align-items-center">
              <img className="me-2" src={scotland_flag} />
              <Card.Title as="h2" className="mb-0">
                Scotland
              </Card.Title>
            </Card.Body>
          </Card>
        </div>
        <div ref={itemsGroupTwoRef} className="items d-flex">
          <Card className="border-0 bg-transparent">
            <Card.Body className="d-flex align-items-center">
              <img className="me-2" src={tunisia_flag} />
              <Card.Title as="h2" className="mb-0">
                Tunsia
              </Card.Title>
            </Card.Body>
          </Card>
          <Card className="border-0 bg-transparent">
            <Card.Body className="d-flex align-items-center">
              <img className="me-2" src={france_flag} />
              <Card.Title as="h2" className="mb-0">
                France
              </Card.Title>
            </Card.Body>
          </Card>
          <Card className="border-0 bg-transparent">
            <Card.Body className="d-flex align-items-center">
              <img className="me-2" src={qatar_flag} />
              <Card.Title as="h2" className="mb-0">
                Qatar
              </Card.Title>
            </Card.Body>
          </Card>
          <Card className="border-0 bg-transparent">
            <Card.Body className="d-flex align-items-center">
              <img className="me-2" src={cameroon_flag} />
              <Card.Title as="h2" className="mb-0">
                Cameroon
              </Card.Title>
            </Card.Body>
          </Card>
          <Card className="border-0 bg-transparent">
            <Card.Body className="d-flex align-items-center">
              <img className="me-2" src={nigeria_flag} />
              <Card.Title as="h2" className="mb-0">
                Nigeria
              </Card.Title>
            </Card.Body>
          </Card>
          <Card className="border-0 bg-transparent">
            <Card.Body className="d-flex align-items-center">
              <img className="me-2" src={us_flag} />
              <Card.Title as="h2" className="mb-0">
                United States
              </Card.Title>
            </Card.Body>
          </Card>
          <Card className="border-0 bg-transparent">
            <Card.Body className="d-flex align-items-center">
              <img className="me-2" src={portugal_flag} />
              <Card.Title as="h2" className="mb-0">
                Portugal
              </Card.Title>
            </Card.Body>
          </Card>
          <Card className="border-0 bg-transparent">
            <Card.Body className="d-flex align-items-center">
              <img className="me-2" src={malawi_flag} />
              <Card.Title as="h2" className="mb-0">
                Malawi
              </Card.Title>
            </Card.Body>
          </Card>
          <Card className="border-0 bg-transparent">
            <Card.Body className="d-flex align-items-center">
              <img className="me-2" src={sweden_flag} />
              <Card.Title as="h2" className="mb-0">
                Sweden
              </Card.Title>
            </Card.Body>
          </Card>
          <Card className="border-0 bg-transparent">
            <Card.Body className="d-flex align-items-center">
              <img className="me-2" src={senegal_flag} />
              <Card.Title as="h2" className="mb-0">
                Senegal
              </Card.Title>
            </Card.Body>
          </Card>
          <Card className="border-0 bg-transparent">
            <Card.Body className="d-flex align-items-center">
              <img className="me-2" src={sa_flag} />
              <Card.Title as="h2" className="mb-0">
                South Africa
              </Card.Title>
            </Card.Body>
          </Card>
          <Card className="border-0 bg-transparent">
            <Card.Body className="d-flex align-items-center">
              <img className="me-2" src={netherlands_flag} />
              <Card.Title as="h2" className="mb-0">
                Netherlands
              </Card.Title>
            </Card.Body>
          </Card>
          <Card className="border-0 bg-transparent">
            <Card.Body className="d-flex align-items-center">
              <img className="me-2" src={tanzania_flag} />
              <Card.Title as="h2" className="mb-0">
                Tanzania
              </Card.Title>
            </Card.Body>
          </Card>
          <Card className="border-0 bg-transparent">
            <Card.Body className="d-flex align-items-center">
              <img className="me-2" src={germany_flag} />
              <Card.Title as="h2" className="mb-0">
                Germany
              </Card.Title>
            </Card.Body>
          </Card>
          <Card className="border-0 bg-transparent">
            <Card.Body className="d-flex align-items-center">
              <img className="me-2" src={ic_flag} />
              <Card.Title as="h2" className="mb-0">
                Ivory Coast
              </Card.Title>
            </Card.Body>
          </Card>
          <Card className="border-0 bg-transparent">
            <Card.Body className="d-flex align-items-center">
              <img className="me-2" src={scotland_flag} />
              <Card.Title as="h2" className="mb-0">
                Scotland
              </Card.Title>
            </Card.Body>
          </Card>
        </div>
      </div>
    </section>
  );
};

export default SectionTwo;
