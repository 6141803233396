import React, { useEffect, useRef } from "react";
import { Card, Button, Row, Col, Container } from "react-bootstrap";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const SectionThree = () => {
  const sectionRef = useRef(null);
  const scrollRef = useRef(null);
  const text1Ref = useRef(null);
  const text2Ref = useRef(null);
  const text3Ref = useRef(null);
  const text4Ref = useRef(null);
  const text5Ref = useRef(null);

  useEffect(() => {
    let ctx = gsap.context(() => {
      let mm = gsap.matchMedia();
      mm.add("(min-width: 800px)", () => {
        gsap.set(text1Ref.current, { y: 1000 });
        gsap.set(text2Ref.current, { y: 1000 });
        gsap.set(text3Ref.current, { y: 1000 });
        gsap.set(text4Ref.current, { y: 1000 });
        gsap.set(text5Ref.current, { y: 1000 });

        gsap.to(scrollRef.current, {
          scrollTrigger: {
            trigger: scrollRef.current,
            scrub: true,
            pin: false,
            start: "top top",
            end: "bottom center",
          },
        });

        gsap
          .timeline({
            scrollTrigger: {
              trigger: scrollRef.current,
              start: "top top+=100",
              end: "bottom center",
              scrub: true,
              pin: true,
              markers: false,
            },
          })
          .to(text1Ref.current, { y: 0, ease: "sine.inOut" })
          .to(text2Ref.current, { y: 0, ease: "sine.inOut" })
          .to(text3Ref.current, { y: 0, ease: "sine.inOut" })
          .to(text4Ref.current, { y: 0, ease: "sine.inOut" })
          .to(text5Ref.current, { y: 0, ease: "sine.inOut" });
      });
    });

    return () => ctx.revert();
  }, []);

  return (
    <section ref={sectionRef} className="section-3 mt-sm-5 mt-0">
      <Container fluid>
        <div ref={scrollRef}>
          <div className="d-flex align-items-center flex-column">
            <div className="section-text fw-bold text-uppercase pt-sm-5 pt-4">
              Shield Your Reputation!
            </div>
            <div
              ref={text1Ref}
              className="section-text blue fw-bold text-uppercase"
            >
              See the Unseen
            </div>
            <div
              ref={text2Ref}
              className="section-text blue fw-bold text-uppercase"
            >
              Hear the Silence
            </div>
            <div
              ref={text3Ref}
              className="section-text blue fw-bold text-uppercase"
            >
              Touch the Invincible
            </div>
            <div
              ref={text4Ref}
              className="section-text blue fw-bold text-uppercase"
            >
              Smell the Security
            </div>
            <div
              ref={text5Ref}
              className="section-text blue fw-bold text-uppercase"
            >
              Taste the success
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default SectionThree;
