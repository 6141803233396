import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Modal, Button, Form, FormControl } from "react-bootstrap";
import { gsap } from "gsap";
import box1_video from "../assets/videos/cursor-card-01.mp4";
import poster01 from "../assets/images/poster-01.jpg";

const SectionOne = () => {
  const sectionRef = useRef(null);
  const h1Ref = useRef(null);
  const h2Ref = useRef(null);
  const btnLoginRef = useRef(null);
  const box1Ref = useRef(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    let ctx = gsap.context(() => {
      gsap.fromTo(
        h1Ref.current,
        {
          y: 300,
        },
        {
          y: 0,
          ease: "power2.in",
          duration: 1,
          delay: 0.6,
        }
      );
      gsap.fromTo(
        h2Ref.current,
        {
          y: 50,
        },
        {
          y: 0,
          ease: "power2.in",
          duration: 1,
          delay: 1,
        }
      );
      gsap.fromTo(
        btnLoginRef.current,
        {
          y: 50,
        },
        {
          y: 0,
          ease: "power2.in",
          duration: 1,
          delay: 1,
        }
      );
      gsap.fromTo(
        box1Ref.current,
        {
          display: "block",
          opacity: 0,
        },
        {
          display: "block",
          opacity: 0.8,
          ease: "power2.in",
          duration: 0.8,
          delay: 2,
        }
      );
    });

    return () => ctx.revert();
  }, []);

  const onMouseMoveSectionOneHandler = (event) => {
    const mouseX = event.pageX;
    const mouseY = event.pageY;
    const sectionRect = sectionRef.current.getBoundingClientRect();
    const sectionMarginTop = parseInt(
      getComputedStyle(sectionRef.current).marginTop,
      10
    );
    const sectionMarginBottom = parseInt(
      getComputedStyle(sectionRef.current).marginBottom,
      10
    );
    const boxRect = box1Ref.current.getBoundingClientRect();

    const boxCenterX = boxRect.width / 2;
    const boxCenterY = boxRect.height / 2;

    const newX =
      mouseX <= boxCenterX
        ? 0
        : mouseX >= sectionRect.width - boxCenterX
        ? sectionRect.width - boxRect.width
        : mouseX - boxCenterX;
    const newY =
      mouseY - boxCenterY <= sectionRect.top - sectionMarginTop / 2
        ? sectionRect.top - sectionMarginTop / 2
        : mouseY >=
          sectionRect.top +
            sectionRect.height -
            boxCenterY +
            sectionMarginBottom
        ? sectionRect.top +
          sectionRect.height -
          boxRect.height +
          sectionMarginBottom
        : mouseY - boxCenterY;

    gsap.to(box1Ref.current, {
      top: newY,
      left: newX,
      transform: "translate(0, 0)",
      opacity: 0.8,
      duration: 1,
      ease: "power2.out",
    });
  };

  const onMouseLeaveSectionOneHandler = (event) => {
    gsap.killTweensOf(box1Ref.current);
    gsap.to(box1Ref.current, {
      transform: "scale(0, 0)",
      duration: 0.3,
      ease: "power2.out",
    });
  };

  return (
    <section
      ref={sectionRef}
      className="section-1"
      onMouseMove={onMouseMoveSectionOneHandler}
      onMouseLeave={onMouseLeaveSectionOneHandler}
    >
      <Row className="justify-content-center">
        <Col md={10}>
          <h1 className="fw-bolder text-uppercase text-center mb-5">
            <span className="d-block overflow-hidden">
              <span ref={h1Ref} className="d-inline-block text-content">
                Unlock the Power of Digidom
              </span>
            </span>
          </h1>
          <h2 className="h5 fw-bolder text-center pt-5">
            <span className="d-block overflow-hidden pt-5">
              <span ref={h2Ref} className="d-inline-block text-content pt-5">
                Digidom Unleashes the Power of
                <br />
                Decision-Making
              </span>
            </span>
          </h2>
          <div className="d-flex justify-content-center mt-4 pt-2">
            <div className="d-block overflow-hidden btn-login">
              <div ref={btnLoginRef} className="d-inline-block text-content">
                <Button
                  variant="dark text-uppercase px-4 py-2"
                  onClick={() => setShowModal(true)}
                >
                  Safeguard Your Brand Reputation now
                </Button>
                <Modal size="lg" className="modal-contact" centered show={showModal} onHide={() => setShowModal(false) }>
                  <Modal.Header closeButton>
                    <Modal.Title>Contact</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form>
                      <Form.Group className="mb-3">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter First Name"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Last Name"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control type="email" placeholder="Enter Email" />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Message</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          placeholder="Enter Your Message"
                        />
                      </Form.Group>
                    </Form>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary px-4" onClick={() => setShowModal(false)}>Close</Button>
                    <Button variant="dark px-4" onClick={() => setShowModal(false)}>Submit</Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <div
        ref={box1Ref}
        className="box box-1"
        style={{ backgroundImage: "url(" + poster01 + ")" }}
      >
        <video className="w-100" loop muted playsInline autoPlay>
          <source src={box1_video}></source>
        </video>
      </div>
    </section>
  );
};

export default SectionOne;
