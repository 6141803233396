import React, { useEffect, useState, useRef, forwardRef } from "react";
import { Card } from "react-bootstrap";
import { gsap } from "gsap";

const CardFive = forwardRef((props, ref) => {
  const currencyRef = useRef(null);
  const svg1Ref = useRef(null);
  const svg2Ref = useRef(null);

  useEffect(() => {
    let ctx = gsap.context(() => {
      const rotateTimeline = gsap.timeline({
        yoyo: true,
        repeat: -1,
      });
      rotateTimeline.to(currencyRef.current, {
        rotationY: 180,
        duration: 0.5,
        transformOrigin: "center",
        delay: 1,
        onStart: switchCurrency,
      });

      const backgroundTimeline = gsap.timeline({
        yoyo: true,
        repeat: -1,
      });
      backgroundTimeline.fromTo(
        ref.current,
        {
          backgroundColor: "#dee2e6",
        },
        {
          backgroundColor: "#e9ecef",
          duration: 0.5,
          delay: 1,
        }
      );
    });

    return () => ctx.revert();
  }, []);

  const switchCurrency = () => {
    if (svg1Ref.current.style.display == "block")
      setTimeout(() => {
        svg1Ref.current.style.display = "none";
        svg2Ref.current.style.display = "block";
      }, 100);
    else
      setTimeout(() => {
        svg2Ref.current.style.display = "none";
        svg1Ref.current.style.display = "block";
      }, 100);
  };

  return (
    <Card ref={ref} className="card-5 position-absolute border-0">
      <Card.Body className="d-flex justify-content-center align-items-center">
        <h2 className="display-4 fw-bold mb-0" ref={currencyRef}>
          <svg
            ref={svg1Ref}
            width="65"
            height="65"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21 16.5C21 18.433 19.433 20 17.5 20C15.567 20 14 18.433 14 16.5C14 14.567 15.567 13 17.5 13C19.433 13 21 14.567 21 16.5Z"
              fill="#1C274C"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1.25 10C1.25 9.58579 1.58579 9.25 2 9.25H22C22.4142 9.25 22.75 9.58579 22.75 10C22.75 10.4142 22.4142 10.75 22 10.75H2C1.58579 10.75 1.25 10.4142 1.25 10Z"
              fill="#1C274C"
            />
            <path
              opacity="0.5"
              d="M4.1875 9.25L4.6138 7.54479C5.15947 5.36211 5.43231 4.27077 6.24609 3.63538C7.05988 3 8.1848 3 10.4347 3H13.5653C15.8152 3 16.9401 3 17.7539 3.63538C18.5677 4.27077 18.8405 5.36211 19.3862 7.54479L19.8125 9.25H4.1875Z"
              fill="#1C274C"
            />
            <path
              d="M10 16.5C10 18.433 8.433 20 6.5 20C4.567 20 3 18.433 3 16.5C3 14.567 4.567 13 6.5 13C8.433 13 10 14.567 10 16.5Z"
              fill="#1C274C"
            />
            <path
              opacity="0.5"
              d="M9.88379 17.3966C9.9594 17.1104 9.99968 16.8099 9.99968 16.5C9.99968 16.2272 9.96845 15.9616 9.90939 15.7067L10.323 15.4999C11.3787 14.972 12.6214 14.972 13.6771 15.4999L14.09 15.7064C14.0309 15.9614 13.9997 16.227 13.9997 16.5C13.9997 16.8098 14.0399 17.1101 14.1155 17.3961L13.0063 16.8415C12.3728 16.5248 11.6273 16.5248 10.9938 16.8415L9.88379 17.3966Z"
              fill="#1C274C"
            />
          </svg>
          <svg
            ref={svg2Ref}
            width="65"
            height="65"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{display : "none"}}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3.37752 5.08241C3 5.62028 3 7.21907 3 10.4167V11.9914C3 17.6294 7.23896 20.3655 9.89856 21.5273C10.62 21.8424 10.9807 22 12 22V2C11.1886 2 10.405 2.26824 8.83772 2.80472L8.26491 3.00079C5.25832 4.02996 3.75503 4.54454 3.37752 5.08241Z"
              fill="#1C274C"
            />
            <path
              opacity="0.5"
              d="M21 11.9914V10.4167C21 7.21907 21 5.62028 20.6225 5.08241C20.245 4.54454 18.7417 4.02996 15.7351 3.00079L15.1623 2.80472C13.595 2.26824 12.8114 2 12 2V22C13.0193 22 13.38 21.8424 14.1014 21.5273C16.761 20.3655 21 17.6294 21 11.9914Z"
              fill="#1C274C"
            />
          </svg>
        </h2>
      </Card.Body>
    </Card>
  );
});

export default CardFive;
