import React, { useEffect, useRef } from "react";
import { Row, Col, Container, Card } from "react-bootstrap";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import video06 from "../assets/videos/video-06.mp4";
import video07 from "../assets/videos/video-07.mp4";
import finger_print from "../assets/videos/finger-print.webm";
import poster05 from "../assets/images/poster-05.jpg";
import poster06 from "../assets/images/poster-06.jpg";

gsap.registerPlugin(ScrollTrigger);

const SectionSeven = () => {
  const sectionRef = useRef(null);
  const lockRef = useRef(null);
  const textRef = useRef(null);
  useEffect(() => {
    let ctx = gsap.context(() => {
      let mm = gsap.matchMedia();
      mm.add("(min-width: 800px)", () => {
        gsap.set(textRef.current, { marginRight: 100 });
        gsap.to(lockRef.current, {
          scrollTrigger: {
            trigger: sectionRef.current,
            start: "top 80%",
            end: "50% 50%",
          },
          onStart: () => {
            lockRef.current.play();
          },
        });
        gsap.to(textRef.current, {
          scrollTrigger: {
            trigger: sectionRef.current,
            start: "top 80%",
            end: "50% 50%",
          },
          marginRight: 0,
          duration: 2,
        });
      });
      mm.add("(max-width: 799px)", () => {
        lockRef.current.play();
      });
    });
    return () => ctx.revert();
  }, []);

  return (
    <section ref={sectionRef} className="section-7 mt-5">
      <Container fluid>
        <Row className="pt-5">
          <Col className="mt-5 d-flex justify-content-center">
            <div
              className="security-icon"
              style={{ backgroundImage: "url(" + poster05 + ")" }}
            >
              <video ref={lockRef} muted playsInline>
                <source src={video06}></source>
              </video>
            </div>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col>
            <h2 className="text-center fw-bold text-uppercase">
              <span ref={textRef}>Secu</span>
              <span>rity</span>
            </h2>
            <h3 className="text-center fw-bold text-uppercase mb-md-5 mb-0">
              Is Everything
            </h3>
          </Col>
        </Row>
        <Row className="mt-5 justify-content-center">
          <Col md={3} sm={8}>
            <p className="text-center text-muted">
              Discover a new era of brand management with Digidom! Seamlessly
              monitor your brand's online presence across Twitter, Facebook,
              Instagram, blogs, and online press in real time. Our cutting-edge
              AI Reports provide instant insights, empowering you to anticipate
              and navigate potential digital crises with ease. With Digidom,
              make informed decisions based on comprehensive data, all
              conveniently accessible through your personalized dashboard.
              Elevate your brand's reputation and stay steps ahead in the
              dynamic digital landscape.
            </p>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col>
            <Card className="border-0 bg-transparent m-3">
              <Card.Body className="p-0">
                <div className="video-overlay">
                  <video
                    loop
                    muted
                    playsInline
                    autoPlay
                    style={{ backgroundImage: "url(" + poster06 + ")" }}
                  >
                    <source src={video07}></source>
                  </video>
                  <div className="finger-print position-absolute end-0 top-0">
                    <video loop muted playsInline autoPlay>
                      <source src={finger_print}></source>
                    </video>
                  </div>
                  <div className="video-text position-absolute start-0 top-0 w-100 h-100">
                    <div className="w-100 h-100 d-flex align-items-end p-5">
                      <div className="px-sm-5 w-100">
                        <Row>
                          <Col lg={3} md={4}>
                            <p className="text-white fw-bold mb-1">Digidom</p>
                            <p className="text-white">
                              Unleash the power of Digidom and revolutionize
                              your brand management strategy. In an era
                              dominated by digital presence, monitoring and
                              protecting your brand across platforms like
                              Twitter, Facebook, Instagram, blogs, and online
                              press is paramount.
                            </p>
                          </Col>
                          <Col lg={3} md={4}>
                            <p className="text-white fw-bold mb-1">
                              Core Offering
                            </p>
                            <p className="text-white">
                              With Digidom, gain instant insights into your
                              brand's online landscape in real time. Our
                              state-of-the-art AI Reports go beyond mere
                              monitoring; they empower you to anticipate
                              potential digital crises and risks. Navigate the
                              intricate digital realm with confidence, making
                              informed decisions based on data collected and
                              presented seamlessly in your personalized
                              dashboard.
                            </p>
                          </Col>
                          <Col lg={3} md={4}>
                            <p className="text-white fw-bold mb-1">
                              Value Proposition
                            </p>
                            <p className="text-white">
                              Elevate your brand's resilience and reputation.
                              Digidom is not just a monitoring tool; it's your
                              proactive guardian in the digital space. Stay
                              steps ahead, armed with the knowledge to protect
                              and enhance your brand. Embrace the future of
                              brand management with Digidom.
                            </p>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default SectionSeven;
