import React, { useState, useEffect } from "react";
import "./assets/scss/app.scss";
import { ReactLenis } from "@studio-freight/react-lenis";
import SplashScreen from "./components/SplashScreen";
import Header from "./components/Header";
import SectionOne from "./components/SectionOne";
import SectionTwo from "./components/SectionTwo";
import SectionThree from "./components/SectionThree";
import SectionFour from "./components/SectionFour";
// import SectionFive from "./components/SectionFive";
import SectionSix from "./components/SectionSix";
import SectionSeven from "./components/SectionSeven";
import Footer from "./components/Footer";

const App = () => {
  const [loading, setLoading] = useState(true);

  return (
    <>
      {loading ? (
        <SplashScreen setLoading={setLoading} />
      ) : (
        <ReactLenis root>
          <Header />
          <main className="main">
            <SectionOne />
            <SectionTwo />
            <SectionThree />
            <SectionFour />
            {/* <SectionFive /> */}
            <SectionSix />
            <SectionSeven />
          </main>
          <Footer/>
        </ReactLenis>
      )}
    </>
  );
};

export default App;
